<template>
  <v-dialog v-model="dialog" max-width="500">
    <v-card class="pa-4 px-6" rounded="xl">
      <h4>Sobre o Reconhecimento Facial</h4>
      <p class="px-1">
        Utilizamos reconhecimento facial para garantir um acesso seguro e
        facilitado aos eventos que utilizam equipamentos de reconhecimento
      </p>
      <h5>Coleta da Foto</h5>
      <div class="d-flex flex-column gap-3 mb-4">
        <div class="d-flex gap-4 align-center">
          <v-icon size="24">mdi-camera</v-icon>
          <span>
            A captura é realizada online através de qualquer dispositivo com
            câmera frontal que cumpra os padrões de qualidade necessários.
          </span>
        </div>
        <div class="d-flex gap-4 align-center">
          <v-icon size="24">mdi-face-recognition</v-icon>
          <span>
            Assegure-se de estar em um local bem iluminado, sem obstruções no
            rosto. Posicione a câmera de frente, mantenha o rosto centralizado e
            evite expressões marcantes para facilitar a identificação.
          </span>
        </div>
        <div class="d-flex gap-4 align-center">
          <v-icon size="24">mdi-repeat</v-icon>
          <span>
            Em caso de problemas na captura, é possível repetir o processo até
            que uma foto adequada seja obtida.
          </span>
        </div>
      </div>

      <h5>Segurança e Privacidade</h5>
      <div class="d-flex flex-column gap-3 mb-4">
        <div class="d-flex gap-4 align-center">
          <v-icon size="24">mdi-lock</v-icon>
          <span>
            Seus dados são estritamente usados para segurança e controle de
            acesso, não sendo compartilhados com terceiros em nenhuma hipótese.
          </span>
        </div>
        <div class="d-flex gap-4 align-center">
          <v-icon size="24">mdi-shield-check</v-icon>
          <span>
            A captura ocorre de maneira segura no seu dispositivo, é transmitida
            com criptografia de ponta a ponta e armazenada em servidores
            protegidos localizados no Brasil.
          </span>
        </div>
      </div>
      <v-btn text block @click="openRemove" v-if="hasFace">Solicitar remoção da foto</v-btn>
      <v-btn text block @click="close">Fechar</v-btn>
    </v-card>
    <remove-modal ref="removeModal" />
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import RemoveModal from "../../../../components/global/account/face/RemoveModal.vue";
export default {
  name: "FaceHelpModal",
  components: { RemoveModal },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    open() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    openRemove() {
      this.$refs.removeModal.open();
    },
  },
  computed: {
    ...mapGetters("auth", ["user"]),

    hasFace() {
      return this.user?.Biometrics.find((b) => b.type === "FACE");
    },
  },
  mounted() {
    this.$parent.$on("face-help", this.open);
  },
};
</script>

<style></style>
