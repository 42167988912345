<template>
  <div>
    <!-- <v-alert
      style="
        position: fixed;
        bottom: 0px;
        left: 10px;
        z-index: 10;
        width: calc(100% - 20px);
      "
      :value="isOffline"
      type="error"
      border="bottom"
      >
      <b>Você está offline.</b>
      Verifique sua conexão com a internet e tente tentaremos novamente.
    </v-alert> -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data: () => ({
    interval: null,
    lastTokenRenewal: Date.now(),
    isHidden: false,
  }),
  computed: {
    ...mapGetters("auth", ["isAuthenticated"]),
  },
  methods: {
    ...mapActions("auth", ["renewToken"]),
    async rToken(force = false) {
      try {
        if (!force) {
          const fiveMinutes = 5 * 60 * 1000;
          if (Date.now() - this.lastTokenRenewal < fiveMinutes) return;
        }
        if (this.isOffline || this.isHidden || !this.isAuthenticated) return;

        let response = await this.renewToken();
        if (response) {
          this.lastTokenRenewal = Date.now();
          return true;
        }

        if (this.$route.name == "sign-in") return false;

        let currentUrl = this.$route.fullPath;
        this.$router.push({
          path: "/auth",
          query: { redirect: currentUrl },
        });

        return false;
      } catch (e) {
        console.log(e);
      } finally {
        this.cronRenewToken();
      }
    },
    changeVisibility() {
      this.isHidden = document.hidden;
      if (!this.isHidden) this.rToken();
    },
    cronRenewToken() {
      clearInterval(this.interval);

      // Verificar a cada minuto, em vez de 5, para tentar renovar o token mais cedo quando a aba voltar a ficar ativa
      this.interval = setInterval(() => {
        this.rToken();
      }, 60 * 1000);
    },
  },
  mounted() {
    this.rToken(true);
  },
  beforeMount() {
    document.addEventListener("visibilitychange", this.changeVisibility);
  },
  beforeDestroy() {
    clearInterval(this.interval);
    document.removeEventListener("visibilitychange", this.changeVisibility);
  },
};
</script>

<style></style>
