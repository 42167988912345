<template>
  <div class="page-wrap text-center">
    <div class="session-form-hold">
      <v-card rounded="xl" class="">
        <div class="d-flex flex-column gap-7 pa-6" v-if="step === 'hints'">
          <v-icon
            style="position: absolute; right: 15px; top: 15px"
            @click="help"
            size="20"
          >
            mdi-help-circle
          </v-icon>
          <div class="d-flex flex-column justify-center align-center">
            <h2>Registro Facial</h2>
            <p
              class="text-13 mb-0"
              style="overflow-wrap: break-word; max-width: 350px"
            >
              Usaremos seu rosto para autorizar a entrada nos eventos que
              utilizam equipamentos de reconhecimento facial
            </p>
          </div>
          <div
            v-for="(hint, index) in hints"
            :key="index"
            class="d-flex gap-4 align-center opacity-transition px-4"
            :style="{ opacity: hintIndex >= index ? 1 : 0 }"
          >
            <v-icon size="34">{{ hint.icon }}</v-icon>
            <span class="text-15 text-start">{{ hint.text }}</span>
          </div>
          <div
            :style="{ opacity: hintIndex == hints.length ? 1 : 0 }"
            class="opacity-transition d-flex flex-column gap-2"
          >
            <v-checkbox
              v-model="accepted"
              label="Concordo com o uso de minha biometria facial para acesso e segurança dos eventos"
              class="mt-0 text-start"
              color="primary"
              hide-details
            ></v-checkbox>
            <router-link to="/terms" target="_blank" class="text-13">
              <span>Termos de uso</span>
            </router-link>
          </div>

          <v-btn
            color="primary"
            :loading="hintIndex < hints.length || loading"
            :disabled="hintIndex < hints.length || !accepted"
            block
            @click="step = 'capture'"
          >
            Continuar
          </v-btn>
        </div>
        <div v-show="step === 'capture'">
          <div
            style="
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              display: flex;
              justify-content: center;
              align-items: center;
            "
          >
            <v-progress-circular
              indeterminate
              color="primary"
              size="64"
            ></v-progress-circular>
          </div>
          <div
            ref="cameraEl"
            class="rounded-xl face-container"
            style="height: 500px"
          />
        </div>
        <div v-if="step === 'approve'" class="pa-6">
          <div v-if="verifying">
            <v-progress-circular
              indeterminate
              color="primary"
              size="64"
              class="my-4"
            ></v-progress-circular>
            <h5 class="text-center mt-4">Verificando qualidade da foto</h5>
            <p>
              Por favor, aguarde enquanto verificamos a qualidade da foto para
              garantir a precisão do reconhecimento facial
            </p>
          </div>
          <div v-else-if="verifyError">
            <v-alert type="error" icon="mdi-alert">
              {{ verifyError }}
            </v-alert>
            <v-btn color="primary" block @click="retryPhoto">
              Nova Captura
            </v-btn>
            <v-btn color="primary" block @click="verifyFace">
              Verificar novamente
            </v-btn>
          </div>
          <div v-else>
            <v-img :src="face.base64" class="rounded-lg mb-2" />
            <h5 class="text-center">A foto ficou boa?</h5>
            <div class="d-flex gap-2">
              <v-btn
                color="error"
                class="flex-grow-1"
                @click="step = 'capture'"
                :disabled="loading"
              >
                Refazer
              </v-btn>
              <v-btn
                color="success"
                class="flex-grow-1"
                @click="save"
                :loading="loading"
              >
                Confirmar
              </v-btn>
            </div>
            <v-alert v-if="error" type="error" class="mb-0 mt-4" dense>
              {{ error }}
            </v-alert>
          </div>
        </div>
        <div v-if="step === 'success'" class="pa-6 pt-10">
          <v-icon size="56" color="success">mdi-check-circle</v-icon>
          <h5 class="text-center mb-8">Foto salva com sucesso</h5>
          <v-btn color="primary" block @click="finish"> Voltar </v-btn>
        </div>
      </v-card>
      <div class="mt-4 white--text d-flex justify-center gap-2 w-100">
        <v-icon color="white"> mdi-shield-account </v-icon>
        <small class="text-left">
          Os dados serão transmitidos em canal seguro, <br />com criptografia de
          ponta a ponta
        </small>
      </div>
    </div>
    <face-help-modal />
    <renew-token />
  </div>
</template>
<script>
import {
  camera,
  faceDetectionAdapter,
  loadFaceDetectorModels,
} from "@biopassid/face-sdk";

import { themeWhite } from "@/themeConfig.js";
import ACCOUNT from "../../../../services/account";
import { mapGetters } from "vuex";
import FaceHelpModal from "../../../../components/global/account/face/FaceHelpModal.vue";
import RenewToken from "../../../../components/global/RenewToken.vue";

export default {
  components: { FaceHelpModal, RenewToken },
  name: "FaceCapture",
  metaInfo: {
    title: "Captura facial",
  },
  data() {
    return {
      loading: false,
      hints: [
        {
          icon: "mdi-lightbulb-variant",
          text: "Prefira locais claros com fundo neutro.",
        },
        {
          icon: "mdi-emoticon-neutral",
          text: "Use expressão neutra para uma captura precisa.",
        },
        {
          icon: "mdi-camera-front-variant",
          text: "Mantenha a câmera ao nível dos olhos para captura direta.",
        },
        {
          icon: "mdi-glasses",
          text: "Mantenha o rosto descoberto, sem máscaras, óculos, bonés ou acessórios.",
        },
      ],
      hintIndex: -1,
      step: "hints",
      takePicture: null,
      face: null,
      verifying: false,
      verifyError: null,
      error: null,
      token: null,
      accepted: false,
    };
  },
  methods: {
    showHint() {
      setTimeout(() => {
        this.hintIndex++;
        if (this.hintIndex < this.hints.length) this.showHint();
      }, 500);
    },
    retryPhoto() {
      this.step = "capture";
      this.face = null;
      this.token = null;
      this.verifyError = null;
    },
    async verifyFace() {
      console.time("📸 Verifying image quality");
      this.verifying = true;
      try {
        const { token } = await ACCOUNT.face.verify(this.face.base64);
        this.token = token;
      } catch (error) {
        this.verifyError = error.message;
        console.error(error);
      } finally {
        this.verifying = false;
        console.timeEnd("📸 Verifying image quality");
      }
    },

    async save() {
      this.loading = true;
      this.error = null;
      try {
        await ACCOUNT.face.save(this.face.base64, this.token);
        this.step = "success";
      } catch (error) {
        this.error = error.message;
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    finish() {
      const redirect = this.$route.query.redirect || "/app";
      this.$router.push(redirect);
    },

    async loadModels() {
      this.loading = true;
      await loadFaceDetectorModels();
      this.loading = false;
    },
    async handleTakePicture() {
      try {
        const resp = await this.takePicture({
          element: this.$refs.cameraEl,
          faceDetectionAdapter: faceDetectionAdapter,
          i18n: {
            noFacesDetected: "Nenhuma face detectada",
            multipleFacesDetected: "Multiplas faces detectadas",
            moveFaceLeft: "Mova o rosto para a esquerda",
            moveFaceRight: "Mova o rosto para a direita",
            moveFaceUp: "Mova o rosto para cima",
            moveFaceDown: "Mova o rosto para baixo",
            keepStill: "Mantenha o rosto parado e olhe para a câmera",
          },
          options: {
            mask: {
              enabled: true,
              type: "face",
              backgroundColor: "white",
              backgroundOpacity: 0.7,
              frameColor: "black",
              frameThickness: 3,
            },
            legend: {
              title: {
                enabled: true,
                content: "Captura Facial",
                fontSize: "1.5rem",
                fontWeight: "600",
                color: "black",
              },
              subtitle: {
                enabled: true,
                fontSize: "1rem",
                fontWeight: "normal",
                color: "black",
              },
            },
            cameraPresets: {
              aspectRatio: 9 / 10,
              facingMode: "user",
            },
            backButton: {
              enabled: true,
              icon: {
                color: "#333",
              },
            },
            switchButton: {
              enabled: true,
              icon: {
                color: "#333",
              },
            },
            faceDetection: {
              enabled: true,
              autoCapture: true,
              timeToCapture: 3000,
              timeToCaptureFeedbackColor: themeWhite.primary,
              multipleFacesEnabled: false,
              scoreThreshold: 0.1,
            },
          },
        });

        this.step = "approve";
        this.face = resp;
        this.verifyFace();
      } catch (error) {
        this.step = "hints";
        console.error(error);
      }
    },
    help() {
      this.$emit("face-help");
    },
  },
  watch: {
    step: {
      immediate: true,
      handler(step) {
        if (step === "capture") {
          this.handleTakePicture();
        }
      },
    },
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  created() {
    if (!this.user) this.$router.push("/auth");
    if (this.user?.Biometrics.find((b) => b.type === "FACE"))
      this.$router.replace({
        path: "/auth/account/face/",
        query: {
          ...this.$route.query,
          redirect: this.$route.query?.redirect || undefined,
        },
      });
  },
  mounted() {
    if (this.$route.query.help) this.help();
    this.showHint();
    const { takePicture } = camera();
    this.takePicture = takePicture;
    this.loadModels();
  },
};
</script>
<style lang="scss">
.page-wrap {
  background: radial-gradient(
      circle at 50% 50%,
      rgba(0, 0, 0, 50%) 0%,
      rgba(0, 0, 0, 90%) 90vw
    ),
    url(/img/logo/auth-background.png), black;
  display: flex;
  background-size: cover;
  background-position: center;
  align-items: center;
  padding: 40px 1rem;
  height: 100%;
  min-height: 100vh;
}
.session-form-hold {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

.opacity-transition {
  transition: opacity 0.3s;
}

#biopass_camera {
  width: 100%;
  height: 100%;
  border-radius: 16px;
}

.face-container {
  div {
    border-radius: 16px;
    svg {
      border-radius: 16px;
    }
  }
}
</style>
