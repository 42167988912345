<template>
  <v-dialog v-model="dialogRemove" max-width="400">
    <v-card class="pa-4 px-6" rounded="xl">
      <h4>Solicitar remoção da foto</h4>
      <div v-if="this.hasFace">
        <p class="px-1">
          Se você deseja remover sua foto cadastrada do sistema, por favor,
          clique no botão abaixo.
        </p>
        <h5>Avisos</h5>
        <div class="d-flex flex-column gap-3 mb-4">
          <div class="d-flex gap-4 align-center">
            <v-icon size="24">mdi-camera-off</v-icon>
            <span> A foto não será mais considerada válida imediatamente </span>
          </div>
          <div class="d-flex gap-4 align-center">
            <v-icon size="24">mdi-image-lock</v-icon>
            <span>
              A imagem fica armazenada por 6 meses por questões legais e de
              segurança
            </span>
          </div>
          <div class="d-flex gap-4 align-center">
            <v-icon size="24">mdi-hand-back-left-off</v-icon>
            <span>
              Não é possível reverter a alteração e será necessário recadastrar
              o rosto
            </span>
          </div>
          <v-checkbox
            v-model="checked"
            label="Aceitos os termos e desejo continuar com a remoção"
            dense
            hide-details
          ></v-checkbox>
        </div>
        <v-btn color="warning" :disabled="!checked" block @click="removeFace">
          Remover cadastro facial
        </v-btn>
      </div>
      <v-alert v-else type="success" border="bottom">
        Sua solicitação de remoção foi realizada com sucesso. Seu reconhecimento
        facial está inativo e será apagado dentro de seis meses.
      </v-alert>
      <v-btn text block @click="close">Fechar</v-btn>
    </v-card>
  </v-dialog>
</template>

<script>
import ACCOUNT from "../../../../services/account";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "RemoveModal",
  data() {
    return {
      dialogRemove: false,
      checked: false,

      loading: false,
    };
  },
  methods: {
    ...mapActions("auth", ["renewToken"]),
    open() {
      this.dialogRemove = true;
    },
    close() {
      this.dialogRemove = false;
    },
    async removeFace() {
      this.loading = true;
      try {
        const response = await ACCOUNT.face.remove(this.user.id);
        this.removeSuccess = response.message;
        this.removeError = null;
        await this.renewToken();
      } catch (error) {
        this.removeError = error.message;
        this.removeSuccess = null;
        console.error(error);
      } finally {
        this.loading = false;
        // this.close();
      }
    },
  },
  computed: {
    ...mapGetters("auth", ["user"]),

    hasFace() {
      return this.user?.Biometrics.find((b) => b.type === "FACE");
    },
  },
};
</script>

<style></style>
